import { Box, Skeleton, Stack } from '@mui/material';

export default function ClientListItemSkeleton( { length = 7 }: { length?: number } ): JSX.Element {
	return (
		<Box>
			{[ ...new Array( length ) ].map( ( _, index ) => (
				<Stack
					key={index}
					direction='row'
					alignItems='center'
					spacing={1}
					sx={{ p: .5 }}>
					<Skeleton
						animation='wave'
						width={50}
						height={70}
						sx={{ borderRadius: '100%' }}
					/>
					<Stack width='100%'>
						<Skeleton
							animation='wave'
							width='80%'
							height={10}
						/>
						<Skeleton
							animation='wave'
							width='100%'
							height={10}
							sx={{ mt: 1 }}
						/>
					</Stack>
				</Stack>
			) )}
		</Box>
	);
}

