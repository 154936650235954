import { InvoicesRead } from '@/data/commerce/invoice.graphql';
import MobileRowPanel from '@/pages/dashboard/commerce/components/mobileRow';
import RowPanel from '@/pages/dashboard/commerce/invoices/rowPanel';
import { columns } from '@/pages/dashboard/commerce/invoices/table';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Order, QueryInvoicesReadArgs } from '@/types/schema';
import GraphqlTable from '../graphqlTable';

export default function Invoices( { variables } ) {
	const { staff } = useUserInfo();
	if ( !variables?.filter?.clientId && !variables?.filter?.invoicesStatuses ) return null;
	
	return (
		<GraphqlTable<Order, QueryInvoicesReadArgs>
			showFooter
			searchable
			disableUrlSync
			returnNullOnEmpty
			queryKey='invoice'
			query={InvoicesRead}
			variables={{
				options: {
					filter: {
						client  : variables.filter.clientId,
						status  : variables.filter.invoicesStatuses,
						metadata: { templateName: null },
					},
				},
			}}
			subscription={{ INVOICE: staff.company.id }}
			columns={columns}
			initialState={{
				hiddenColumns: [
					'id',
					'taxTotal',
					'updatedAt',
					'client.id',
					'metadata.signature',
					'payments',
					'houseAccount.id',
					'houseAccount',
					'management:location',
					'type',
					'po',
				],
			}}
			expandedComponent={( row ) => <RowPanel row={row}/>}
			mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
		/>
	);
}
