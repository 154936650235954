import { gql } from '@apollo/client';

export const StatementHouseAccounts = gql`query HouseAccountsRead_e8fd($options: FilterOptions) {
	houseAccountsRead(options: $options) {
		items {
			id # hidden
			logo
			name
			contact
			email
			number
			phone
			metadata
			active
			statement
			invoiceDates
			memberId
			pastDue
			balance
			addresses {
				line1
				line2
				city
				state
				postalCode
				country
			}
			client {
				id
				name
				contact
				email
				logo
				phone
				metadata
				cell
				addresses {
					id
					line1
					line2
					city
					country
					state
					postalCode
				}
				gateway {
					externalId
				}
			}
			company {
				id
				logo
				name
				contact
			}
			updatedAt
		}
		count
	}
}`;
