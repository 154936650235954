import { Client } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { Box, Fade, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { Dispatch, SetStateAction } from 'react';
import Loading from '../loading';
import SwipeableTabViews, { SegmentControlStyles } from '../swipeableTabViews';
import Estimates from './estimates';
import Invoices from './invoices';
import Orders from './orders';
import { Monthly, Yearly } from './yearlyMonthly';

export default function ClientStatementContent( {
	clientStatement,
	commercesData,
	selectedClient,
	commercesIsFetching,
	selectedTabIndex,
	setSelectedTabIndex,
	clientView,
	variables,
	setVariables,
	month,
	setMonth,
	year,
	setYear,
	dates,
}: {
	dates: any,
	clientView: boolean,
	variables: any,
	month: Date,
	year: Date,
	clientStatement: boolean,
	selectedClient: Client,
	commercesIsFetching: boolean,
	selectedTabIndex: number,
	commercesData: any,
	setSelectedTabIndex: Dispatch<SetStateAction<number>>,
	setMonth: Dispatch<SetStateAction<Date>>,
	setYear: Dispatch<SetStateAction<Date>>,
	setVariables: Dispatch<SetStateAction<any>>
} ) {
	let tabs: string[], tabsContent: JSX.Element[];
	const timezone = getBrowserTimezone();
	if ( clientView ) {
		tabs = [ 'Month', 'Year' ];
		tabsContent = [
			<Monthly
				key='month'
				clientView={clientView}
				months={dates?.months}
				client={selectedClient}
				setMonth={setMonth}
				month={month}
				setYear={setYear}
				variables={variables}
				setVariables={setVariables}
				timezone={timezone}
			
			/>,
			<Yearly
				key='year'
				clientView={clientView}
				years={dates?.years}
				client={selectedClient}
				setYear={setYear}
				setMonth={setMonth}
				year={year}
				variables={variables}
				setVariables={setVariables}
				timezone={timezone}
			/>,
		];
	} else {
		tabs = [ 'Invoices', 'Orders', 'Estimates' ];
		tabsContent = [
			<Invoices key='invoices' variables={variables}/>,
			<Orders key='orders' variables={variables}/>,
			<Estimates key='estimates' variables={variables}/>,
		];
	}
	
	if ( commercesIsFetching ) {
		return <Loading/>;
	}
	
	if ( !clientView && !selectedClient ) return (
		<Fade in timeout={1000}>
			<Typography p={1} className='center'>
				Please select a client to view their statement.
			</Typography>
		</Fade>
	);
	
	if ( ( clientStatement || clientView ) && isEmpty( commercesData ) ) return (
		<Fade in timeout={1000}>
			<Typography p={1} className='center'>
				No commerces are available for this client.
			</Typography>
		</Fade>
	);
	
	return (
		<Fade in timeout={1000}>
			<Box>
				<SwipeableTabViews
					renderTabs={tabs}
					tab={selectedTabIndex}
					renderContent={( index ) => tabsContent[ index ]}
					tabsWrapperProps={{ sx: { ...SegmentControlStyles } }}
					setTab={( index ) => setSelectedTabIndex( index )}
				/>
			</Box>
		</Fade>
	);
	
}
