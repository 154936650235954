import { OrdersRead } from '@/data/commerce/order.graphql';
import MobileRowPanel from '@/pages/dashboard/commerce/components/mobileRow';
import RowPanel from '@/pages/dashboard/commerce/orders/rowPanel';
import { columns } from '@/pages/dashboard/commerce/orders/table';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Order, QueryOrdersReadArgs } from '@/types/schema';
import { useTheme } from '@mui/material';
import GraphqlTable from '../graphqlTable';

export default function Orders( { variables } ) {
	const { staff } = useUserInfo();
	const theme = useTheme();
	const darkMode = theme.palette.mode === 'dark';
	if ( !variables?.filter?.clientId && !variables?.filter?.ordersStatuses ) return null;
	
	return (
		<GraphqlTable<Order, QueryOrdersReadArgs>
			showFooter
			searchable
			disableUrlSync
			returnNullOnEmpty
			queryKey='order'
			query={OrdersRead}
			variables={{
				options: {
					filter: {
						client: variables.filter.clientId,
						status: variables.filter.ordersStatuses,
					},
				},
			}}
			columns={columns}
			subscription={{ ORDER: staff.company.id }}
			initialState={{
				hiddenColumns: [
					'id',
					'taxTotal',
					'updatedAt',
					'client.id',
					'metadata.signature',
					'payments',
					'houseAccount.id',
					'houseAccount',
					'management:location',
					'type',
					'po',
				],
			}}
			expandedComponent={( row ) => <RowPanel row={row} darkMode={darkMode}/>}
			mobileRenderRow={( order ) => <MobileRowPanel data={order as any}/>}
		/>
	);
}
