import { CommercesStatement } from '@/data/commerce/commerce.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { PaymentTypeChip } from '@/hooks/paymentTypeChip';
import CommerceStatusChip from '@/pages/dashboard/commerce/chips/commerceStatusChip';
import MobileRowPanel from '@/pages/dashboard/commerce/components/mobileRow';
import {
	CalculateClients,
	CalculateDate,
	CalculateNumber,
	CalculatePaymentType,
	CalculateTotal,
	CalculateUnique,
} from '@/pages/dashboard/commerce/components/tableHelpers';
import RowPanel from '@/pages/dashboard/commerce/invoices/rowPanel';
import { Client, Order, QueryCommercesStatementArgs } from '@/types/schema';
import { Box, Chip, Fade, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { Column } from 'react-table';
import TextFieldInputLabel from '../form/inputLabel';
import GraphqlTable from '../graphqlTable';
import { formattedMonth, formattedYear } from './index';

export const statementColumns: ( args: string ) => Array<Column<any> | null> = ( client ) => [ {
	accessor: 'number',
	Header  : 'Number',
	props   : { style: { minWidth: 135 } },
	width   : 10,
	Footer  : ( { rows } ) => CalculateNumber( rows, 'Invoice' ),
}, {
	accessor   : ( row ) => row.client?.name || row.client?.contact,
	Header     : 'Client',
	sortType   : 'select',
	filterByKey: 'client.name',
	width      : 15,
	props      : { style: { minWidth: 148 } },
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, client === 'client' ? {
	accessor   : 'company' as any,
	Header     : 'common:company',
	filterByKey: [ 'company.name', 'company.contact' ],
	width      : 20,
	props      : { style: { minWidth: 168 } },
	Cell       : ( { value } ) => value?.name || value?.contact || '-',
	Footer     : ( { rows } ) => CalculateClients( rows, [
		'company.name',
		'company.contact' ], 'Company' ),
} : null, {
	accessor      : 'type',
	Header        : 'Type',
	disableSortBy : true,
	disableFilters: true,
	width         : 10,
	props         : { style: { minWidth: 148 } },
	Cell          : ( { value } ) => (
		<Chip
			variant='alpha'
			color={value === 'ORDER' ? 'success' : 'warning'}
			label={value}
		/>
	),
}, {
	accessor     : 'status',
	Header       : 'Status',
	filterOptions: [
		'PAID',
		'ACCOUNT',
		'SENT',
		'DRAFT',
		'PARTIALLY_PAID',
		'CANCELLED',
		'REFUNDED',
		'STANDING',
		'COMPLETED',
		'VIEWED',
	],
	width        : 15,
	props        : { style: { minWidth: 80 } },
	Cell         : ( data ) => <CommerceStatusChip cellData={data}/>,
	Footer       : ( { rows } ) => CalculateUnique( rows, 'status' ),
}, {
	accessor   : 'payments',
	Header     : 'Payment Type',
	width      : 10,
	sortType   : 'select',
	filterByKey: 'payments.some.type',
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => <PaymentTypeChip payments={value} name={'invoice'}/>,
	Footer     : ( { rows } ) => CalculatePaymentType( rows ),
}, {
	accessor: ( row ) => row?.serviceDate || row.updatedAt,
	Header  : 'Service Date',
	sortType: 'datetime',
	width   : 15,
	props   : { style: { minWidth: 118 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) as any : '',
	Footer  : ( { rows } ) => CalculateDate( rows, 'serviceDate' ),
}, {
	accessor: 'taxTotal',
	Header  : 'Tax',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'taxTotal', true ),
}, {
	accessor: 'grandTotal',
	Header  : 'Total',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 90 } },
	Cell    : ( { value, row } ) => {
		const tipsTotal = row.values.payments?.reduce( ( sum, payment ) => {
			if ( payment.tip > 0 ) {
				sum += payment.tip;
			}
			return sum;
		}, 0 );
		
		return currencyFormat( Math.abs( +value + tipsTotal ) ) as any;
	},
	Footer  : ( { rows } ) => CalculateTotal( rows, 'grandTotal', true ),
} ].filter( Boolean );

export function Yearly( {
	years,
	client,
	setYear,
	year,
	setMonth,
	variables,
	setVariables,
	timezone,
	clientView = false,
}: {
	years: {
		value: Date | string,
		label: string
	}[],
	client: Client,
	year: Date,
	setMonth: Dispatch<SetStateAction<Date>>,
	setYear: Dispatch<SetStateAction<Date>>,
	setVariables: Dispatch<SetStateAction<any>>,
	variables: any,
	timezone: string,
	clientView?: boolean
} ) {
	const theme = useTheme();
	const darkMode = theme.palette.mode === 'dark';
	if ( !client && !clientView ) return null;
	
	return (
		<Box key='Year'>
			<Stack my={2} width={300}>
				<TextFieldInputLabel label='Select Year'/>
				<Select
					value={year || years[ 0 ].value || ''}
					onChange={( e ) => {
						setVariables( {
							...variables,
							year: formattedYear( e.target.value ) || undefined,
						} );
						setYear( e.target.value as Date );
						setMonth( null );
					}}>
					{years?.map( ( year, i ) => (
						<MenuItem key={i} value={year.value as string}>
							Year of {year.label}
						</MenuItem>
					) )}
				</Select>
			</Stack>
			<Fade in={( clientView || ( variables?.year || years?.length ) && variables?.clientId )}>
				<Box>
					<GraphqlTable<Order, QueryCommercesStatementArgs>
						showFooter
						searchable
						disableUrlSync
						queryKey='commerces'
						query={CommercesStatement}
						variables={{
							clientId: variables?.clientId,
							year    : variables?.year || years[ 0 ].value,
							options : { filter: { timezone } },
						}}
						columns={statementColumns( clientView ? 'client' : null )}
						initialState={{ hiddenColumns: [ 'type' ] }}
						expandedComponent={( row ) => <RowPanel row={row} darkMode={darkMode}/>}
						mobileRenderRow={( commerce ) => <MobileRowPanel data={commerce as any}/>}
					/>
				</Box>
			</Fade>
		</Box>
	);
}

export function Monthly( {
	months,
	client,
	setMonth,
	month,
	setYear,
	variables,
	setVariables,
	clientView = false,
	timezone,
}: {
	months: {
		value: Date | string,
		label: string
	}[],
	client: Client,
	month: Date,
	setMonth: Dispatch<SetStateAction<Date>>,
	setYear: Dispatch<SetStateAction<Date>>,
	setVariables: Dispatch<SetStateAction<any>>,
	variables: any,
	timezone: string,
	clientView?: boolean
} ) {
	const theme = useTheme();
	const darkMode = theme.palette.mode === 'dark';
	if ( !clientView && !client ) return null;
	
	return (
		<Box key='Month'>
			<Stack my={2} width={300}>
				<TextFieldInputLabel label='Select Month'/>
				<Select
					value={month || months[ 0 ].value || ''}
					onChange={( e ) => {
						setVariables( {
							...variables,
							month: formattedMonth( e.target.value ) || undefined,
						} );
						setMonth( e.target.value as Date );
						setYear( null );
					}}>
					{months?.map( ( month, i ) => (
						<MenuItem key={i} value={month.value as string}>
							{month.label}
						</MenuItem>
					) )}
				</Select>
			</Stack>
			<Fade in={( clientView || ( variables?.month || months?.length ) && variables?.clientId )}>
				<Box>
					<GraphqlTable<Order, QueryCommercesStatementArgs>
						showFooter
						searchable
						disableUrlSync={!clientView}
						queryKey='commerces'
						query={CommercesStatement}
						variables={{
							clientId: variables?.clientId,
							month   : month || variables?.month || months[ 0 ].value,
							options : { filter: { timezone } },
						}}
						columns={statementColumns( clientView ? 'client' : null )}
						initialState={{ hiddenColumns: [ 'updatedAt', 'type', 'houseAccount.id', 'houseAccount' ] }}
						expandedComponent={( row ) => <RowPanel row={row} darkMode={darkMode}/>}
						mobileRenderRow={( commerce ) => <MobileRowPanel data={commerce as any}/>}
					/>
				</Box>
			</Fade>
		</Box>
	);
}
