import AsyncLoadingSwitch from '@/components/form/asyncLoading/switch/loadingSwitch';
import { useGraphQL, useInfiniteGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { CompanyWrite } from '@/data/company.graphql';
import { ClientsRead, ClientsWithOrders } from '@/data/management/client.graphql';
import safeDateFns from '@/helpers/safeFormat';
import useInfiniteQuery from '@/hooks/useInfiniteQuery';
import { ellipsisSX } from '@/pages/dashboard/management/houseAccounts/invoice/selectLeftGrid';
import { areDatesValid } from '@/pages/dashboard/management/reports/itemReportFilters';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Client, MutationCompanyWriteArgs, QueryClientsReadArgs, QueryClientsWithOrdersArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import {
	Avatar,
	Box,
	Fade,
	FormControlLabel,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	Switch,
	Typography,
} from '@mui/material';
import { DateRangePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { useQueryClient } from '@tanstack/react-query';
import { addMonths, endOfDay, startOfMonth, subMonths } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { flatten, isEmpty, isEqual, toUpper, unionBy } from 'lodash-es';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalSkeleton from '../animations/conditionalSkeleton';
import CustomCheckBox from '../customCheckBox';
import TextFieldInputLabel from '../form/inputLabel';
import DateRangePickerFields from '../muiDatePickerFields/dateRangePickerSlot';
import SearchBar from '../searchBar';
import ClientListItemSkeleton from '../skeletons/clientListItemSkeleton';

export default function SelectClient( {
	selectedClient,
	setSelectedClient,
	checkedClients,
	setCheckedClients,
	setVariables,
	clientStatement,
	statuses,
	month,
	year,
	setMonth,
	setYear,
	setDates,
	currentTab,
	setCurrentTab,
	isYear,
	setIsYear,
}: {
	selectedClient: Client | undefined,
	setSelectedClient: Dispatch<SetStateAction<Client>>,
	checkedClients: Client[],
	setCheckedClients: Dispatch<SetStateAction<Client[]>>,
	clientStatement: boolean,
	statuses: any,
	setVariables: Dispatch<SetStateAction<any>>,
	month?: Date | null,
	year?: Date | null,
	setMonth?: Dispatch<SetStateAction<Date>>,
	setYear?: Dispatch<SetStateAction<Date>>,
	setDates?: Dispatch<SetStateAction<Date[]>>,
	currentTab?: number,
	setCurrentTab?: Dispatch<SetStateAction<number>>,
	isYear?: boolean,
	setIsYear?: Dispatch<SetStateAction<boolean>>
} ) {
	const prevClientsDataRef = useRef<Client[] | undefined>( undefined );
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const timeZoneDate = utcToZonedTime( new Date(), getBrowserTimezone() );
	const startOfCurrentMonth = startOfMonth( timeZoneDate );
	const endOfCurrentDay = endOfDay( timeZoneDate );
	const [ checkBoxMinusIcon, setCheckBoxMinusIcon ] = useState( false );
	const [ value, setValue ] = useState<Date>( startOfCurrentMonth );
	const [ customDates, setCustomCustomDates ] = useState( [ startOfCurrentMonth, endOfCurrentDay ] );
	const [ search, setSearch ] = useState( '' );
	const timezone = getBrowserTimezone();
	const excludePaidFromStatement = staff?.company?.metadata?.excludePaidFromStatement;
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const invalidDates = useMemo( () => areDatesValid( customDates ), [ customDates ] );
	
	const { data, isFetching: isFetchingClient } = useGraphQL<QueryClientsReadArgs>( {
		query    : ClientsRead,
		queryKey : [ 'client' ],
		variables: {
			options: {
				filter: {
					...search?.length ? {
						$or: [ 'name', 'contact', 'email' ].map( ( field ) =>
							( { [ field ]: { $ilike: `%${search}%` } } ) ),
					} : {},
				},
			},
		},
	}, { enabled: Boolean( search?.length ) } );
	
	const {
		      data: clientsWithOrders,
		      isFetched,
		      isFetching: isFetchingClientsWithOrders,
		      hasNextPage,
		      fetchNextPage,
		      count,
	      }
		      = useInfiniteGraphQL<QueryClientsWithOrdersArgs>( {
		query    : ClientsWithOrders,
		queryKey : [ 'clientsWithOrders' ],
		variables: {
			options: {
				limit : 100,
				filter: {
					month: currentTab === 1 || isYear ? null : safeDateFns.utcToZonedTime( month, timezone )
						?.toISOString(),
					year: currentTab === 1 || !isYear ? null : safeDateFns.utcToZonedTime( year, timezone )
						?.toISOString(),
					customDates: currentTab === 1 && !invalidDates
						? customDates.map( ( date ) => safeDateFns.utcToZonedTime( date, timezone )
							?.toISOString() )
						: null,
					timezone,
					excludePaidFromStatement,
				},
			},
		},
	}, {
		enabled: !Boolean( search?.length ) && ( currentTab === 0
			? Boolean( isYear ? year : month )
			: !isEmpty( customDates ) ),
	} );
	
	const lastElementRef = useInfiniteQuery( fetchNextPage, hasNextPage, isFetchingClientsWithOrders );
	
	const clientsData = useMemo( () => {
		if ( Boolean( search ) ) {
			return data?.clientsRead?.items;
		} else {
			if ( !clientsWithOrders?.pages ) return [];
			return unionBy( flatten( clientsWithOrders?.pages.map( ( { data } ) =>
				data.clientsWithOrders.items ) ), 'id' );
		}
	}, [ data, clientsWithOrders ] );
	
	useEffect( () => {
		if ( !isEqual( prevClientsDataRef.current, clientsData ) ) {
			prevClientsDataRef.current = clientsData;
		}
	}, [ clientsData ] );
	
	useEffect( () => {
		if ( isYear ) {
			setYear( value );
			setMonth( null );
		} else {
			setMonth( value );
			setYear( null );
		}
	}, [ value, isYear ] );
	
	useEffect( () => {
		setDates( customDates );
	}, [ customDates ] );
	
	const handleSelectIndividualClient = async ( e, client: Client ) => {
		e.stopPropagation();
		if ( allChecked ) {
			setCheckBoxMinusIcon( false );
		}
		const checkedClientIds = checkedClients?.map( ( c ) => c.id );
		let remainingCheckedClients: Client[];
		if ( checkedClientIds?.includes( client.id ) ) {
			remainingCheckedClients = checkedClients.filter( ( c ) => c.id !== client.id );
			setCheckedClients( remainingCheckedClients );
		} else {
			remainingCheckedClients = [ ...checkedClients, client ];
			setCheckedClients( remainingCheckedClients );
		}
		// Update checkBoxMinusIcon state based on the new selection state
		if ( allChecked ) {
			setCheckBoxMinusIcon( false );
		} else {
			const isPartialSelection = remainingCheckedClients.length > 0 && remainingCheckedClients.length < clientsData?.length;
			
			setCheckBoxMinusIcon( isPartialSelection );
		}
	};
	
	const allChecked = clientsData?.length > 0 ? clientsData?.length === checkedClients?.length : false;
	
	return (
		<Paper
			variant='borderless'
			sx={{
				position     : 'sticky',
				display      : 'flex',
				flexDirection: 'column',
				// flexGrow     : 1,
				// height       : 850,
			}}>
			<Stack spacing={2} sx={{ p: 2 }}>
				<Typography>
					Filter By:
				</Typography>
				<RadioGroup
					value={currentTab}
					onChange={( e, value ) => {
						setSelectedClient( null );
						setCheckedClients( [] );
						setCheckBoxMinusIcon( false );
						setCurrentTab( +value );
					}}>
					<FormControlLabel
						value={0}
						control={<Radio/>}
						label='Monthly/Yearly'
					/>
					<FormControlLabel
						value={1}
						control={<Radio/>}
						label='Custom Dates'
					/>
				</RadioGroup>
				{currentTab === 0 && (
					<Fade in>
						<Stack key='monthly' direction='row' spacing={1} alignItems='center'>
							<Box>
								<TextFieldInputLabel label={isYear ? 'Year' : 'Month'}/>
								<DateTimePicker<Date>
									value={value}
									views={isYear ? [ 'year' ] : [ 'year', 'month' ]}
									minDate={new Date( '2019-01-01' )}
									maxDate={new Date()}
									ampm={false}
									closeOnSelect={false}
									onChange={( newValue ) => setValue( newValue )}
								/>
							</Box>
							<Switch
								checked={isYear}
								onChange={async ( e, checked: boolean ) => {
									setIsYear( checked );
									if ( checked ) {
										setValue( month
											? utcToZonedTime( new Date( month ), getBrowserTimezone() )
											: timeZoneDate );
									}
								}}
							/>
							Yearly
						</Stack>
					</Fade>
				)}
				{currentTab === 1 && (
					<Fade in>
						<Stack key='custom' direction='row' spacing={1} alignItems='center'>
							<DateRangePicker
								value={customDates as any}
								minDate={!customDates?.[ 0 ] ? subMonths( new Date(), 1 ) : undefined}
								maxDate={addMonths( customDates?.[ 0 ] || new Date(), 24 )}
								slots={{ field: DateRangePickerFields }}
								onChange={( newValue ) => {
									if ( !isEmpty( newValue.filter( Boolean ) ) ) {
										setCustomCustomDates( [ newValue[ 0 ], endOfDay( newValue[ 1 ] ) ] );
									} else {
										setCustomCustomDates( [ null, null ] );
									}
								}}
							/>
						</Stack>
					</Fade>
				)}
				{( isOwner || isAdmin ) && (
					<ListItem disableGutters>
						<ListItemText
							primary={t( 'settings:exclude-paid-invoices' )}
							secondary={t( 'settings:remove-paid-from-statements' )}
						/>
						<AsyncLoadingSwitch
							checked={staff?.company.metadata.excludePaidFromStatement}
							onChange={async ( e, checked: boolean ) => {
								await mutateGraphQL<MutationCompanyWriteArgs>( {
									mutation : CompanyWrite,
									variables: { input: { metadata: { excludePaidFromStatement: checked } } },
								} );
								await queryClient.invalidateQueries( [ 'user' ] );
							}}
						/>
					</ListItem>
				)}
				{!isEmpty( clientsData ) && (
					<Stack direction='row' spacing={1} alignItems='center'>
						<Typography>Total: {count ? count : clientsData.length ?? 0}
						</Typography>
						{!isEmpty( checkedClients ) && (
							<Typography>
								| Selected: {checkedClients?.length}
							</Typography>
						)}
					</Stack>
				)}
			</Stack>
			<Box sx={{ p: 1, overflowY: 'auto' }}>
				<Stack direction='row' spacing={1} alignItems='center'>
					{clientStatement && !isEmpty( clientsData ) && (
						<CustomCheckBox
							checked={checkBoxMinusIcon ? false : allChecked}
							indeterminate={checkBoxMinusIcon}
							onClick={() => {
								if ( checkBoxMinusIcon || !allChecked ) {
									setCheckedClients( clientsData );
								} else {
									setCheckedClients( [] );
								}
								setCheckBoxMinusIcon( false );
							}}
						/>
					)}
					<SearchBar
						fullWidth
						autoFocus
						size='small'
						placeholder='Search to see more clients'
						setSearch={setSearch}
						sx={{ pb: 1 }}
					/>
				</Stack>
				<ConditionalSkeleton
					isLoading={isFetchingClient || isFetchingClientsWithOrders && !isFetched}
					skeleton={( <ClientListItemSkeleton length={10}/> )}>
					{!isEmpty( clientsData ) ? (
						<Stack spacing={1}>
							{clientsData.map( ( client, index ) => {
								const isSelected = selectedClient?.id === client.id;
								const checkedClientIds = checkedClients?.map( ( c ) => c.id );
								const isChecked = checkedClientIds?.includes( client.id );
								const isLast = clientsData.length === index + 1;
								return (
									<Box
										key={index}
										ref={isLast ? lastElementRef : undefined}>
										<ListItemButton
											selected={!( checkedClients?.length > 1 ) && isSelected}
											onClick={() => {
												if ( client.id === selectedClient?.id ) {
													setSelectedClient( null );
												} else {
													setSelectedClient( client );
												}
												
												if ( clientStatement ) {
													setVariables( { clientId: client.id } );
												} else {
													setVariables( {
														filter: {
															invoicesStatuses : { $in: statuses.invoices },
															estimatesStatuses: { $in: statuses.estimates },
															ordersStatuses   : { $in: statuses.orders },
															clientId         : client?.id,
														},
													} );
												}
											}}>
											<ListItemAvatar>
												<Stack direction='row' spacing={1} pr={1}>
													{clientStatement && (
														<CustomCheckBox
															checked={isChecked}
															onClick={async ( e ) => handleSelectIndividualClient( e, client )}
														/>
													)}
													<Avatar src={client.logo || ''}>
														{toUpper( client.name?.[ 0 ] ) || toUpper( client.contact?.[ 0 ] ) || '-'}
													</Avatar>
												</Stack>
											</ListItemAvatar>
											<ListItemText
												primary={client.contact || '-'}
												primaryTypographyProps={{ sx: ellipsisSX }}
												secondaryTypographyProps={{ sx: ellipsisSX }}
												secondary={client?.email || '-'}
											/>
											<ListItemText primary={client.metadata.c}/>
										</ListItemButton>
									</Box>
								);
							} )}
							{isFetchingClientsWithOrders && (
								<Box sx={{ justifyContent: 'center' }}>
									<ClientListItemSkeleton length={10}/>
								</Box>
							)}
						</Stack>
					) : (
						<Typography sx={{ textAlign: 'center', p: 2 }}>
							{Boolean( search ) ? 'No Clients Found '
								: value ? 'No Clients With Order Available For Selected Period'
									: 'Please select the period to view clients with orders.'}
						</Typography>
					)}
				</ConditionalSkeleton>
			</Box>
		</Paper>
	);
}
